<template>
  <div class="login-wrap">
    <login-form
      :login-text="$t('login')"
      :validate-email-msg-required="$t('validateEmailMsgRequired')"
      :validate-email-msg-email="$t('validateEmailMsgEmail')"
      :placeholder-email="$t('placeholderEmail')"
      :placeholder-pass="$t('placeholderPass')"
      :validate-pass-msg="$t('validatePassMsg')"
      :min-pass-length="$t('minPassLength')"
      registration-link="/registration"
      :registration-text="$t('registration')"
      :no-acc="$t('noAcc')"
      :welcome-text="$t('welcome')"
      :need-confirm-text="this.$t('needConfirm')"
      :new-user-email="newUserEmail"
      :email-confirmed="emailConfirmed"
      @login="login"
      @showNeedConfirmMessage="showNeedConfirmMessage"
      @showConfirmedMessage="showConfirmedMessage"
    />
  </div>
</template>

<script>
import LoginForm from 'smarket-ui/src/components/forms/LoginForm'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters([
      'newUserEmail',
      'emailConfirmed',
    ]),
  },
  methods: {
    async login ({ email, password }) {
      try {
        await this.$store.dispatch('login', { email, password })
      } catch (error) {
        this.loginErrorMsg = this.$t('loginError')
        return
      }
      try {
        const profile = await this.$store.dispatch('getProfile')
        if (this.newUserEmail !== '' && email === this.newUserEmail) {
          await this.$store.dispatch('removeNewUser')
        }
        await this.$router.push(profile.isWelcomePageShown ? '/profile' : '/welcome')
      } catch (error) {
        await this.$store.dispatch('showError', error)
        await this.$router.push('/profile')
      }
    },
    showNeedConfirmMessage () {
      this.$store.dispatch('setHeaderMessage', {
        text: `${this.$t('successConfirm')} ${this.$t('successWeSent')} <b>${this.newUserEmail}</b>`,
        hasButton: true,
        buttonText: this.$t('resend'),
        buttonAction: 'resendConfirmEmail',
        icon: 'mail-lines',
      })
    },
    showConfirmedMessage () {
      this.$store.dispatch('setHeaderMessage', {
        text: `${this.$t('emailConfirmedStart')} <b>${this.newUserEmail}</b> ${this.$t('emailConfirmedEnd')}`,
        hasButton: false,
        alignCenter: true,
        icon: 'checkmark',
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('hideHeaderNotification')
    next()
  },
}

</script>

<style lang="scss" scoped>
.login-wrap {
  max-width: 1000px;
  margin: 0 auto;
  padding: 115px 0 75px 0;
}

.content-wrap__inner {
 padding-bottom: 0;
}
</style>
